<template>
  <div class="members main-cnt">
    <div class="left-wp">
      <div class="input-wp">
        <el-input v-model.trim="filterText" clearable @keypress="getTreeData()" placeholder="输入组织名称搜索"
          :suffix-icon="Search" />
      </div>
      <div class="tree-wp" v-loading="treeLoading" element-loading-text="数据加载中">
        <el-scrollbar style="height: 100%">
          <el-tree ref="tree" :data="treeData" :props="{
              children: 'product_list',
              label: 's_name',
            }" show-checkbox check-strictly accordion node-key="tree_monitor_id"
            :default-expanded-keys="defaultExpanded" :default-checked-keys="defaultChecked"
            :expand-on-click-node="false" @node-expand="nodeExpand" @node-collapse="nodeCollapse">
            <template #default="{ node, data }">
              <span class="custom-tree-node">
                <span @click="nodeClick(data)">
                  {{ node.label }}
                  {{
                    "(" +
                    data.device_online_count +
                    "/" +
                    data.device_all_count +
                    ")"
                  }}

                  <span v-if="!data.s_id" class="text-gray">{{
                    data.mg_status == 2 ? "(禁用)" : ""
                  }}</span>
                </span>

                <span v-if="data.s_id">
                  <a style="margin-left: 8px">
                    <!-- <el-icon color="#0072F6"><CaretTop  /></el-icon> -->
                    <!-- <el-icon color="#0072F6"><CaretBottom /></el-icon> -->
                  </a>
                </span>
                <span v-else>
                  <el-icon color="#0072F6" v-if="
                      authData.indexOf('n_rxBqE3Dz1tL36oG8dcUsJv8a6VhY') != -1
                    " @click="updateGroup(data)">
                    <Edit />
                  </el-icon>
                  <a style="margin-left: 8px" v-if="
                      authData.indexOf('n_h6cnins9P254QJ7dVP93VOQN6l6K') != -1
                    " @click="removeGroup(data)">
                    <el-icon color="#0072F6">
                      <Close />
                    </el-icon>
                  </a>
                </span>
              </span>
            </template>
          </el-tree>
        </el-scrollbar>
      </div>
    </div>
    <div class="right-wp">
      <div class="title">
        {{ titleName.length > 0 ? titleName.join("-") : "IOT列表" }}
      </div>
      <div class="content">
        <common-table ref="userTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
          :apiName="BasicApi.iotList" :filters="filters" :extraParame="extraParame" :columns="tableColumns"
          @statusChange="statusChange" @previewMonitor="previewMonitor" @updateMonitor="updateMonitor"
          @delete="deleteMonitor" @edit="memberEdit">
          <template #iot_online_status="{ row }">
            <span :style="{
                color: row.iot_online_status == 2 ? ' #67C23A' : '#ccc',
              }">{{ row.iot_online_status == 2 ? "在线" : "离线" }}</span>
          </template>

          <template #operate>
            <el-button type="primary" round @click="showOrganizationAdd"
              v-if="authData.indexOf('n_KWLvYxoInLjPJozs26b62Ksh9SCZ') != -1">
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增产品
            </el-button>
            <el-button class="black-bg-btn" round @click="showAddDialog"
              v-if="authData.indexOf('n_KWLvYxoInLjPJozs26b62Ksh9SCZ') != -1">
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增设备
            </el-button>
          </template>
        </common-table>
      </div>
    </div>

    <!-- 新增产品弹框 -->
    <w-dialog ref="organization" class="organization" :title="isEditGroup ? '编辑产品' : '新增产品'" width="38%"
      btnWidth="140px" top="20vh" :confirmText="isEditGroup ? '确认编辑' : '确认新增'" @wConfirm="orgAddConfirm">
      <el-form class="org-form" ref="orgFormObj" :model="orgForm" :rules="orgRules" labelPosition="top">
        <el-form-item label="产品名称" prop="iop_name">
          <el-input v-model="orgForm.iop_name" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品编号" prop="iop_no">
          <el-input v-model="orgForm.iop_no" placeholder="请输入产品编号"></el-input>
        </el-form-item>
        <el-form-item v-if="!isEditGroup" label="所属园区" prop="department">
          <el-select v-model="orgForm.iop_sid" clearable placeholder="请选择所属园区">
            <el-option :label="item.s_name" :value="item.s_id" v-for="(item, index) in projectSelect" :key="index">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 新增/编辑设备弹框 -->
    <w-dialog ref="addDialog" class="add-dialog" :title="isEdit ? '编辑设备' : '新增设备'" width="40%" btnWidth="140px"
      top="15vh" :confirmText="isEdit ? '确认编辑' : '确认新增'" @wConfirm="userAddConfirm">
      <el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-form-item label="设备名称" prop="iot_name">
          <el-input v-model="ruleForm.iot_name" placeholder="请输入设备名称"></el-input>
        </el-form-item>

        <el-form-item label="排序顺序" prop="iot_sort">
          <el-input v-model="ruleForm.iot_sort" type="number" placeholder="请输入排序顺序"></el-input>
        </el-form-item>
        <el-form-item label="所属园区" prop="department">
          <el-select v-model="ruleForm.s_id" @change="changeProject" clearable placeholder="请选择所属园区">
            <el-option :label="item.s_name" :value="index" v-for="(item, index) in treeData" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分组" prop="iot_iogid">
          <el-select v-model="ruleForm.iot_iogid" clearable placeholder="请选择所属产品">
            <el-option :label="item.s_name" :value="item.iop_id" v-for="item in groupList" :key="item.iop_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
      </el-form>
    </w-dialog>

    <!-- 权限范围 弹框 -->
    <RoleRange ref="roleRangeRef" @submit_suc="roleRangeSuc"></RoleRange>
  </div>
</template>
<script setup>
  import { ref, reactive, onMounted, computed, watch, nextTick } from "vue";
  import { Search, Close, Edit } from "@element-plus/icons-vue";
  import { ElMessageBox, ElMessage } from "element-plus";

  import { BasicApi } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import RoleRange from "@/views/basic/children/RoleRange.vue";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);

  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );

  const groupList = ref([]);
  // 选择园区获取所属分组选项
  const changeProject = (e) => {
    ruleForm.iot_iogid = '';
    groupList.value = treeData.value[e] ? treeData.value[e].product_list : [];
  };

  const self = reactive({
    appKey: "",
    ezvizToken: "",
    platformId: "",
    layout: 0,
    deviceSerial: "",
    channelNo: "",
    startTime: "",
    endTime: "",
    deviceClass: "0",
  });
  const projectSelect = ref([]);
  /**
   * 生命周期
   */
  onMounted(() => {
    getTreeData();

    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code == 200) {
        projectSelect.value = res.Data;
      }
    });
  });

  /** 搜索框绑定值 */
  const filterText = ref("");
  /** 树形结构组件对象 */
  const tree = ref(null);
  /** 树结构加载标识 */
  const treeLoading = ref(false);
  /** 当前选择的组织 */
  const currentOrg = ref(null);
  /** 树形结构 数据 */
  const treeData = ref([]);
  /** 默认展开节点 */
  const defaultExpanded = ref([]);
  /** 默认选择节点 */
  const defaultChecked = ref([]);
  /** 节点点击回调 */
  const extraParame = ref({
    type: 2,
    iop_id: "",
    s_id: "",
  });
  const nodeClick = (node) => {
    extraParame.value.iop_id = node?.iop_id;
    extraParame.value.s_id = node?.s_id;

    currentOrg.value = node;
    tree.value.setCheckedNodes([node]);
    titleName.value = [];
    nextTick(() => {
      userTable.value.tableLoad();
    });
  };

  /** 节点展开事件 */
  const nodeExpand = (data) => {
    defaultExpanded.value.push(data.org_id);
  };
  /** 节点关闭事件 */
  const nodeCollapse = (data) => {
    let index = defaultExpanded.value.findIndex((item) => item == data.org_id);
    defaultExpanded.value.splice(index, 1);
  };
  /** 获取组织树形结构数据 */
  const getTreeData = () => {
    treeLoading.value = true;
    BasicApi.iotList({
      keywords: filterText.value,
      type: 1,
    }).then((res) => {
      if (res.Code === 200) {
        treeData.value = res.Data ? res.Data : [];
        treeData.value.forEach((resp) => {
          resp.product_list.forEach((group) => {
            group.s_name = group.iop_name;
          });
        });
      } else {
        let msg = res.Message ? res.Message : "获取树形组织结构失败！";
        ElMessage.error(msg);
      }
      treeLoading.value = false;
    });
  };

  /** 当前组织及父级名称 */
  const titleName = ref([]);

  /** 筛选条件列表 */
  const filters = ref([
    {
      name: "keywords",
      filterType: "search",
      value: "",
      placeholder: "搜索设备名称",
    },
  ]);
  /** 表格对象 */
  const userTable = ref(null);
  /** 当前操作 表格行数据 */
  const currentRow = ref(null);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "iot_id",
      label: "设备ID",
      minWidth: 120,
      color: "--text-third-color",
    },
    {
      prop: "iot_name",
      label: "设备名称",
      minWidth: 120,
      color: "--text-third-color",
    },

    {
      prop: "iot_sort",
      label: "设备排序编号",
      minWidth: 120,
      color: "--text-color",
    },
    {
      type: "customRender",
      prop: "iot_online_status",
      label: "在线状态",
      minWidth: 180,
    },
    {
      type: "switch",
      prop: "iot_status",
      label: "业务状态",
      minWidth: 180,
      token: "n_rxBqE3Dz1tL36oG8dcUsJv8a6VhY",
    },

    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 200,
      bottons: [
        // {
        // 	name: '预览',
        // 	action: 'previewMonitor',
        // 	token: 'n_YPZkKmguidRMULpQrhCjHVfIEXx2',
        // 	className: 'black-font-btn'
        // },
        {
          name: "编辑",
          action: "updateMonitor",
          token: "n_rxBqE3Dz1tL36oG8dcUsJv8a6VhY",
          className: "theme-font-btn",
        },
        {
          name: "删除设备",
          action: "delete",
          token: "n_h6cnins9P254QJ7dVP93VOQN6l6K",
          className: "black-font-btn",
        },
      ],
    },
  ]);
  /** 状态改变处理 */
  const statusChange = (row) => {
    let data = {
      iot_id: row.iot_id,
      iot_status: row.iot_status == 1 ? 2 : 1,
      type: 2,
    };
    BasicApi.iotUpdate(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("状态修改成功！");
        // 重新获取套餐列表数据
        userTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "状态修改失败！";
        ElMessage.error(msg);
      }
    });
  };
  /** 预览 */
  const previewVideo = ref(null);
  const previewMonitor = async (row) => {
    currentRow.value = row;
    previewVideo.value.show();
    self.deviceSerial = row.me_hik_no;
    self.channelNo = 1;
    // 初始化视频控件
  };
  /** 删除设备 */
  const deleteMonitor = (row) => {
    ElMessageBox.confirm("确认删除?", "提示", {
      confirmButtonText: "确认删除",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        BasicApi.iotDel({
          type: 2,
          iot_id: row.iot_id,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("删除成功！");
            // 重新获取表格数据
            userTable.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : "删除失败！";
            ElMessage.error(msg);
          }
        });
      })
      .catch((err) => {
        ElMessage.error(err);
      });
  };
  /** 表格编辑 */
  const memberEdit = (row) => {
    currentRow.value = row;
    isEdit.value = true;
    ruleForm.name = row.u_name;
    ruleForm.tel = row.u_tel;
    ruleForm.department = row.u_duid;
    ruleForm.organization = row.uo_oid;
    ruleForm.role = JSON.parse(row.u_role);
    addDialog.value.show();
    getPostData();
    getRoleData();
  };

  /** 新增组织 弹框 */
  const organization = ref(null);
  /** 组织 表单对象 */
  const orgFormObj = ref(null);
  /** 组织 表单数据对象 */
  const orgForm = reactive({
    iop_name: "", // 组织名称
    iop_id: "", // 组织排序
    iop_no: "",
    iop_sid: "",
  });
  const isEditGroup = ref(false);
  const editGroupData = ref("");
  // 编辑产品
  const updateGroup = (data) => {
    editGroupData.value = data;
    orgForm.iop_name = data.iop_name;
    orgForm.iop_id = data.iop_id;
    orgForm.iop_no = data.iop_no;
    organization.value.show();
    isEditGroup.value = true;
  };

  const removeGroup = (data) => {
    ElMessageBox.confirm("确认删除?", "提示", {
      confirmButtonText: "确认删除",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        BasicApi.iotDel({
          type: 1,
          iop_id: data.iop_id,
        }).then((res) => {
          if (res.Code == 200) {
            ElMessage.success("删除成功！");
            // 重新获取一下组织数据
            getTreeData();
          } else {
            let msg = res.Message ? res.Message : "删除失败！";
            ElMessage.error(msg);
          }
        });
      })
      .catch((err) => {
        ElMessage.error(err);
      });
  };
  /** 组织 表单规则对象 */
  const orgRules = reactive({
    iop_name: [
      {
        required: true,
        message: "请输入分组名称",
        trigger: "blur",
      },
    ],
  });
  /** 弹出 新增产品 弹框 */
  const showOrganizationAdd = () => {
    // 表单验证重置
    if (orgFormObj.value) {
      orgFormObj.value.resetFields();
    }
    isEditGroup.value = false;
    orgForm.iop_name = "";
    orgForm.iop_id = "";
    orgForm.iop_no = "";
    orgForm.iop_sid = "";
    organization.value.show();
  };
  /** 新增产品 确认 */
  const orgAddConfirm = () => {
    orgFormObj.value.validate((valid) => {
      if (valid) {
        organization.value.isLoading = true;
        let data = {
          iop_name: orgForm.iop_name,
          iop_no: orgForm.iop_no,
          type: 1,
        };
        let url = "iotStore";
        if (isEditGroup.value) {
          url = "iotUpdate";
          data.iop_id = editGroupData.value.iop_id;
        } else {
          data.iop_sid = orgForm.iop_sid;
        }
        BasicApi[url](data).then((res) => {
          organization.value.isLoading = false;
          if (res.Code === 200) {
            ElMessage.success(
              isEditGroup.value ? "编辑产品成功！" : "新增产品成功"
            );
            organization.value.close();
            defaultChecked.value = tree.value.getCheckedKeys();
            // 重新获取一下组织数据
            getTreeData();
          } else {
            let msg = res.Message ? res.Message : "新增产品失败！";
            ElMessage.error(msg);
          }
        });
      }
    });
  };

  /** 新增/编辑 成员弹框 */
  const addDialog = ref(null);
  /** 当前是否是编辑操作 */
  const isEdit = ref(false);
  /** 表单对象 */
  const addForm = ref(null);
  /** 表单数据对象 */
  const ruleForm = reactive({
    iot_iogid: "", // 监控分组ID
    iot_name: "", // 设备名称
    iot_sort: "", // 排序 顺序
    type: "", // 设备验证码
  });
  /** 表单规则对象 */
  const rules = reactive({
    iot_name: [
      {
        required: true,
        message: "请输入设备名称",
        trigger: "blur",
      },
    ],
    iot_iogid: [
      {
        required: true,
        message: "请输入设备序列号",
        trigger: "blur",
      },
    ],
    iot_sort: [
      {
        required: true,
        message: "请输入排序 顺序",
        trigger: "blur",
      },
    ],
  });
  /** 岗位列表数据 */
  const postList = ref([]);
  /** 角色列表数据 */
  const roleList = ref([]);
  /** 弹出 新增/编辑成员 弹框 */
  const showAddDialog = () => {
    // 表单验证重置
    if (addForm.value) {
      addForm.value.resetFields();
    }
    ruleForm.s_id = "";
    ruleForm.iot_name = "";
    ruleForm.iot_id = "";
    ruleForm.iot_sort = "";
    ruleForm.iot_status = "";
    groupList.value = [];
    isEdit.value = false;
    addDialog.value.show();
    getPostData();
    getRoleData();
  };
  /** 获取岗位数据 */
  const getPostData = () => {
    BasicApi.getDuty().then((res) => {
      if (res.Code === 200) {
        postList.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取部门数据失败！";
        ElMessage.error(msg);
      }
    });
  };
  /** 获取角色数据 */
  const getRoleData = () => {
    BasicApi.getRole().then((res) => {
      if (res.Code === 200) {
        roleList.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取角色数据失败！";
        ElMessage.error(msg);
      }
    });
  };
  /** 确认添加设备 */
  const userAddConfirm = () => {
    addForm.value.validate((valid) => {
      if (valid) {
        addDialog.value.isLoading = true;
        let data = {
          iot_name: ruleForm.iot_name,
          iot_sort: ruleForm.iot_sort,
          iot_status: ruleForm.iot_status,
          iot_iogid: ruleForm.iot_iogid,
          type: 2,
        };
        let url = "iotStore";
        if (isEdit.value) {
          data.iot_id = currentRow.value.iot_id;
          url = "iotUpdate";
        }
        BasicApi[url](data).then((res) => {
          addDialog.value.isLoading = false;
          let text = isEdit.value ? "编辑设备" : "新增设备";
          if (res.Code === 200) {
            ElMessage.success(`${text}成功！`);
            addDialog.value.close();
            if (isEdit.value) {
              // 重新获取表格数据
              userTable.value.tableLoad();
            } else {
              userTable.value.tableLoad();
              getTreeData();
            }
          } else {
            let msg = res.Message ? res.Message : `${text}失败！`;
            ElMessage.error(msg);
          }
        });
      }
    });
  };

  // 成员权限范围弹框挂载
  const roleRangeRef = ref(null);
  // 打开编辑弹出框
  const updateMonitor = (row) => {
    addDialog.value.show();
    isEdit.value = true;
    ruleForm.iot_name = row.iot_name;
    ruleForm.iot_id = row.iot_id;
    ruleForm.iot_sort = row.iot_sort;
    ruleForm.iot_status = row.iot_status;
    currentRow.value = row;
  };
  // 编辑权限范围回显
  const roleRangeSuc = () => {
    userTable.value.tableLoad();
  };
/** 变更成员权限范围 **/
</script>

<style lang="scss">
  .el-cascader {
    width: 100%;
  }

  .video-switch {
    height: 48px;
    width: 900px;
    background: #333333;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .video-switch-tab {
    height: 48px;
    margin: 0 auto;
    transition: transform 0.3s;
    position: relative;
  }

  .video-switch-tab-line {
    position: absolute;
    width: 88px;
    top: 0;
    left: 0;
    height: 3px;
    background-color: #e72528;
    z-index: 1;
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .video-switch-tab-btn {
    font-size: 12px;
    font-weight: 801;
    float: left;
    font-family: "MicrosoftYaHei-Bold";
    line-height: 48px;
    color: var(--text-white-color);
    opacity: 0.6;
    width: 88px;
    box-sizing: content-box;
    text-align: center;
  }

  .members {
    font-family: "Source Han Sans CN";
    display: flex;
    background-color: var(--theme-bg-color);

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }

    .left-wp {
      flex: 1;
      border-radius: 10px;
      overflow: hidden;
      background-color: var(--bg-content-color);
      padding: 20px 0;
      box-sizing: border-box;

      .input-wp {
        padding: 0 15px;
      }

      >.tree-wp {
        margin-top: 15px;
        height: calc(100% - 58px);

        .el-tree {
          padding: 0 15px;

          .el-tree-node {
            .el-tree-node__content {
              height: 40px;

              >label.el-checkbox {
                display: none;
              }

              .el-tree-node__expand-icon {
                font-size: 16px;
              }
            }

            .el-tree-node__content:hover {
              background-color: var(--search-bg-color);
            }
          }

          .el-tree-node:focus {
            >.el-tree-node__content {
              background-color: var(--search-bg-color);
            }
          }

          .el-tree-node.is-checked {
            >.el-tree-node__content {
              background-color: var(--search-bg-color);

              >.el-tree-node__label {
                color: var(--theme-color);
              }

              >.el-tree-node__expand-icon {
                color: var(--theme-color);
              }

              >.el-tree-node__expand-icon.is-leaf {
                color: transparent;
              }
            }
          }
        }
      }
    }

    .right-wp {
      flex: 3;
      margin-left: 16px;
      border-radius: 10px;
      background-color: var(--bg-content-color);

      .content {
        padding: 20px;
      }
    }

    .organization {
      .el-dialog {
        min-width: 640px;

        .el-dialog__body {
          padding: 0 15px 50px;

          .org-form {
            display: flex;
            flex-wrap: wrap;

            .el-form-item {
              width: 50%;
              padding: 20px 15px 0;
              margin-bottom: 0;

              .el-form-item__content {
                .el-select {
                  width: 100%;

                  .el-input {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .add-dialog {
      .el-dialog {
        min-width: 840px;

        .el-dialog__body {
          padding: 0 15px 40px;

          .add-form {
            display: flex;
            flex-wrap: wrap;

            .el-form-item {
              width: 50%;
              padding: 20px 15px 0;
              margin-bottom: 0;

              .el-form-item__content {
                .el-select {
                  width: 100%;

                  .el-input {
                    width: 100%;
                  }
                }
              }
            }

            .el-form-item.block {
              width: 100%;
            }

            .el-divider {
              margin: 30px 0 0 15px;
              width: calc(100% - 30px);
            }
          }
        }
      }
    }

    .auth-dialog {
      .el-dialog {
        min-width: 840px;

        .el-dialog__body {
          padding: 0;

          .mcontent {
            padding: 25px 25px 50px;
            min-height: 600px;

            .name {
              font-size: 15px;
              color: var(--top-text-color);
              margin-bottom: 15px;
            }

            .select {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
</style>